.riskFormHeaderSection{
    background-color: #166fb8;
    color: white;
    /* background-color: #1a237e; */
    /* color: #e4e4e4; */
    text-align: center;
}

.formHeader{
    font-size: 16px;
    user-select: none;
}

#viewAllButton{
    background-color: #33c140;
    /* font-size: 17px; */
    width: 125px;
    margin: 2px;
}

#searchButton{
    background-color: #a59b9a;
    /* font-size: 17px; */
    width: 125px;
    margin: 2px;
}

.formHeaderButtonSegment{
    align-self: center !important;
}

.selectedForm{
    background-color: white;
    color: black;
    /* background-color: #1e1e1e; */
}

@media screen and (max-width: 1000px) {
    #viewAllButton{
        width: unset;
    }
    
    #searchButton{
        width: unset;
    }
}