
.table {
    border: 1px solid black;
    border-collapse: collapse;
}

.tr{
    height: 100px !important;
}

.td, .th{
    padding: 5px;
    border: 1px solid black;
    border-collapse: collapse;
}

.riskClassificationSpan{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.riskClassification{
    min-width: 220px;
}

.tableHeading{
    background-color: #89abe4;
}

.critical{
    background-color: #ff0000;
}

.high{
    background-color: #f56500;
}

.moderate{
    background-color: #ffc188;
}

.low{
    background-color: #c4d6f2;
}