.formSection{
    margin: 10px auto;
    width: 90%;
    max-width: 380px;
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.companyName{
    text-align: center;
    font-size: 25px;
}

.Formheader{
    text-align: left;
    font-size: 18px;
}

.form{
    padding: 30px 30px 20px;
}

#forgetPassword{
    color: #808080;
    cursor: pointer;
    font-size: .8rem;
}