.compose{
    border-radius: 40px !important;
    width: 100% !important;
    background-color: #a59b9a !important;
}
.mailMenu{
    color: #202124 !important;
    width: 100% !important;
    border-radius: 40px !important;
    justify-content: flex-start !important;
    padding-left: 20px !important;
}
.selectedMenu{
    background-color: #33c140 !important;
    color: white !important;
}