.content{
    height: 75%;
    margin-top:30px;
    overflow-x: hidden;
    margin-bottom: 10px;
}
.fieldsetSection{
    width: 98%;
    margin: auto;
}
.fieldContent{
    font-size: 14px !important;
}

.fieldContent p:nth-child(1){
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.fieldHeading{
    display: flex;
    justify-content: space-between;
}