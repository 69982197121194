.formHeader{
    font-size: 16px;
    user-select: none;
}
/* .formHeaderGrid{
    width: 12%;
}
.formHeaderGridUnassigned{
    width: 16%;
} */
.viewModal{
    cursor: pointer;
}
.viewModal:hover{
    color: -webkit-link;
}