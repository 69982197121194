.content{
    height: 80%;
    margin-top:30px;
    overflow-x: hidden;
    margin-bottom: 10px;
}
.fieldsetSection{
    width: 98%;
    margin: auto;
}
.fieldContent{
    font-size: 14px !important;
}
.fieldHeading{
    display: flex;
    justify-content: space-between;
}