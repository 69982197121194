.header{
    font-size: 22px !important;
}
.userName{
    font-weight: 700;
    font-size: 14px !important;
}
.userEmail{
    color: #5e5e5e !important;
    font-size: 12px !important;
    vertical-align: middle;
}
.receivers{
    color: #5e5e5e !important;
    font-size: 12px !important;
    vertical-align: middle;
}
.timeStamp{
    color: #5e5e5e !important;
    font-size: 12px !important;
    vertical-align: middle;
}
.replyBtn{
    color: #444746 !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    border: 1px solid #747775 !important;
    border-radius: 18px !important;
    padding: 4px 20px !important;
}