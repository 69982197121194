.fieldset{
    width: 100%;
    margin-bottom: 25px;
    padding: 10px 10px 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    max-width: 100%;
    min-inline-size: unset;
}

.legend{
    /* color: rgba(0, 0, 0, 0.6); */
    color: #0c436e;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
}