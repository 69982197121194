.content{
    height: 80%;
    margin-top:30px;
    overflow-x: hidden;
    margin-bottom: 10px;
}
.fieldsetSection{
    width: 98%;
    margin: auto;
}
.fieldContent{
    font-size: 14px !important;
}

.fieldContent p:nth-child(1){
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.fieldHeading{
    display: flex;
    justify-content: space-between;
}

.historyFieldHeading{
    display: flex;
    justify-content: space-between;
    color: gray;
    margin-left: 70px;
}
.historyFieldContent{
    font-size: 13px !important;
}
.boxContainer {
    display: inline-flex; /* or inline-flex */
    background-color: #f5f3f3;
    border: 1px solid #e0e0e0;
    padding: 7px;
    border-radius: 4px;
    margin-left: 50px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  
.boxText {
    color: #000;
    font-size: 13px;
    font-weight: bold;
}
.style.number {
    float: right;
}
.historyParent{
    font-size: 14px !important;
    color: #616161 !important;
}
.historyValue{
    font-size: 14px;
}
.historyArrow{
    font-size: 18px !important;
    vertical-align: middle !important;
}
.commentText{
    border : 1px solid #ccc;
    border-radius : 4px;
    font-size: 14px !important;
}
.spaceBetween{
    display: flex;
    justify-content: space-between;
}