@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

*{
  font-family: 'Open Sans', sans-serif;
  /* color: #e4e4e4; */
  /* background-color: #1e1e1e; */
}

/* main{
  background-color: #1e1e1e;
  color: #e4e4e4 !important;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* .css-10hburv-MuiTypography-root{
  font-size: 15px !important;
} */

.appBarColourTheme{
  background-color: #2dc5ff !important;
  color: white !important;
  font-weight: bold;
  /* background-color: #1e1e1e !important;
  color: #e4e4e4 !important; */
}
.legendColour{
    color: #0c436e !important;
}
.submenuColourTheme{
  background-color: #0c436e !important;
  color: white !important;
  /* background-color: #1e1e1e !important;
  color: #e4e4e4 !important; */
}
.selectedMenu{
  background-color: #0a2c49 !important;
  /* background-color: #311b92 !important;
  color: #e4e4e4 !important; */
}

.blueBadgeColour{
  background-color: #5c8de1;
}

.greenBadgeColour{
  background-color: #00b2a8;
}

.required{
  color: red;
}

.buttonProperty:hover{
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.04);
  color: white;
}

/* .divider{
  background-color: #e4e4e4;
} */

/* .bgDark{
  background-color: #1e1e1e;
} */

/* .cDark{
  color: #e4e4e4 !important;
} */

.icon{
  font-size: 22px !important;
  cursor: pointer;
  color: #9e9e9e;
}

.dropdownIcon{
  font-size: 20px !important;
  cursor: pointer;
  color: #9e9e9e;
}

.addIcon:hover{
  color: #69f0ae;
}

.editIcon:hover{
  color: #ffc107;
}

.deleteIcon:hover{
  color: #f44336;
}

.riskOneGreenSmallBtn{
  background-color: #33c140 !important;
  color: white !important;
  font-size: 12px !important;
}

.riskOneGreenBtn{
  background-color: #33c140 !important;
  color: white !important;
}

.riskOneBlueHeader{
    background-color: #166fb8;
}

.taCenter{
  text-align: center !important;
}

.fieldset{
  width: 100% !important;
  margin-bottom: 10px !important;
  padding: 10px !important;
  border: 1px solid #ddd !important;
  border-radius: 5px !important;
  margin: 10px 0px;
}

.formHeaderSection{
  background-color: #166fb8;
    color: white;
    text-align: center;
}

.selectedFormHeader{
  background-color: white;
  color: black;
}

.formHeader{
  font-size: 16px;
  user-select: none;
}

.hyperLink:hover{
  color: -webkit-link;
  cursor: pointer;
}

.hyperLinkActive{
  cursor: pointer;
  color: -webkit-link;
}

.buttonSection{
  text-align: center;
}
/* .breadcrumbs{
  font-size: 13px !important;
  color: #e4e4e4 !important;
} */

/* .roButton{
  color: #1e1e1e !important;
  background-color: #e8eaf6 !important;
} */

/* .roTextButton{
  color: #e8eaf6 !important;
} */

.rejectedRow{
  background-color: #eeeeee;
  cursor: default;
}

.bold{
  font-weight: bold;
}

.lable{
  font-size: 13px !important;
  /* color: #e4e4e4; */
}

.fs14{
    font-size: 14px !important;
}

.p0{
  padding: 0px !important;
}

.ptb0{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.ptb2{
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.p5{
  padding: 5px !important;
}

.plr5{
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.p10{
  padding: 10px !important;
}

.pb10{
    padding-bottom: 10px !important;
}

.ptb10{
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.plr10{
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.plr10{
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ptlr10{
  padding-top: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pblr10{
  padding-bottom: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.p15{
  padding: 15px !important;
}

.ptb15{
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.plr15{
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.ptlr15{
  padding-top: 15px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pblr15{
  padding-bottom: 15px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.p20{
  padding: 20px !important;
}

.ptb30{
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.plr40{
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.mt0{
  margin-top: 0px !important;
}

.mlr1{
    margin-left: 1px !important;
    margin-right: 1px !important;
}

.mlr2{
    margin-left: 2px !important;
    margin-right: 2px !important;
}

.m5{
  margin: 5px !important;
}

.mt5{
  margin-top: 5px !important;
}

.mb5{
  margin-bottom: 5px !important;
}

.mtb5{
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mlr5{
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.m10{
  margin: 10px !important;
}

.mb10{
  margin-bottom: 10px !important;
}

.ml10{
  margin-left: 10px !important;
}

.mt10{
  margin-top: 10px !important;
}

.mr10{
    margin-right: 10px !important;
}

.mlr10{
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mtb10{
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.m15{
  margin: 15px !important;
}

.mt15{
  margin-top: 15px !important;
}

.mb15{
  margin-bottom: 15px !important;
}

.ml15{
  margin-left: 15px !important;
}

.mlr15{
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.mb20{
  margin-bottom: 20px !important;
}

.m20{
  margin: 20px !important;
}

.mt20{
    margin-top: 20px !important;
}

.mtb20{
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mt25{
  margin-top: 25px !important;
}

.mt30{
  margin-top: 30px !important;
}

.mb30{
  margin-bottom: 30px !important;
}

.mtb30{
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mt40{
  margin-top: 40px !important;
}

.mt50{
  margin-top: 50px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
