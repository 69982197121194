.filterSection{
    border-width: 1px;
    border-style: solid;
    border-color: rgba(224, 224, 224, 1);
    border-radius: 4px 4px 0px 0px;
    border-bottom: transparent;
}








.gridContainerStyle {
    border-width: 1px;
    border-style: solid;
    border-color: #ddd;
    border-radius: 4px;
    padding: 10px;
  }
  
  .gridItemStyle {
    border-width: 1px;
    border-style: solid;
    border-color: #ddd;
    border-radius: 4px;
    padding: 10px;
  }

  .gridItemHeading {
    border-width: 1px;
    border-style: solid;
    border-color: #ddd;
    border-radius: 4px;
    padding: 10px;
    background-color: blue;
    color: white;
    margin-bottom: 10px;
   }

   .content{
    height: 75%;
    margin-top:20px;
    overflow-x: hidden;
    margin-bottom: 10px;
}

.whiteButton {
  background-color: white;
  color: black;
}