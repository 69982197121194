.closeSection{
    text-align: right;
    margin-top: -25px;
    margin-right: -25px;
}

.closeButton{
    cursor: pointer;
    font-size: 25px !important;
}

.closeButton path:nth-child(1){
    color: #c62828 !important;
}

.closeButton path:nth-child(2){
    color: white !important;
}

.closeButton:hover path:nth-child(1){
    color: white !important;
}

.closeButton:hover path:nth-child(2){
    color: #c62828 !important;
}