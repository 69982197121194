.overlay{
    /* display: none; */
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999999;
    background: rgba(255,255,255,0.8) center no-repeat;
    /* url("../image/loader.gif") */
}

.loaderSection{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.fadeIn{
    opacity: 1;
    transition: opacity 1s ease-out;
    display: block;
}

.fadeOut{
    display: none;
    transition: opacity 1s ease-out;
    opacity: 0;
}