.secondLevelSubMenu{
    padding-left: 32px !important;
}

.thirdLevelSubMenu{
    padding-left: 48px !important;
}

.spaceBetween{
    display: flex !important;
    justify-content: space-between !important;
    /* min-width: 260px !important; */
}

.addissue{
    background-color: #008f23 !important;
    color: white !important;
}

.moveissue{
    background-color: #008cde !important;
    color: white !important;
}

.changeissue{
    background-color: #ff9e00 !important;
    color: white !important;
}

.deleteissue{
    background-color: #f60000 !important;
    color: white !important;
}