.td, .th{
    padding: 5px;
}

.table, .th, .td {
    border: 1px solid black;
    border-collapse: collapse;
}
.table{
    width: 100%;
}

.theadTr{
    background-color: #c5d6f2;
}

.tbodyTr{
    background-color: #f2f2f2;
}

.tbodyTd:focus-visible{
    outline: none;
}