.iconSection{
    vertical-align: middle;
    margin-left: 2px;
}
  
.selectFieldSection{
    width: calc(100% - 40px) !important;
}
.mainSection{
    display: flex;
}